import { graphql } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import SEO from '../components/SEO'
import useForm from '../utils/useForm'
import calculatePizzaPrice from '../utils/calculatePizzaPrice'
import formateMoney from '../utils/formatMoney'
import OrderStyles from '../styles/OrderStyles'
import MenuItemStyles from '../styles/MenuItemStyles'
import usePizza from '../utils/usePizza'
import PizzaOrder from '../components/PizzaOrder'
import calculateOrderTotal from '../utils/calculateOrderTotal'

export default function OrderPage({ data }) {
  const pizzas = data.pizzas.nodes
  const { values, updateValue } = useForm({
    name: '',
    email: '',
    coupon: 'FREESHIP',
    mapleSyrup: '',
  })

  const { order, addToOrder, removeFromOrder, error, loading, message, submitOrder } = usePizza({
    pizzas,
    values,
  })

  if (message) {
    return <p>{message}</p>
  }
  return (
    <>
      <SEO title="Order a Pizza!" />
      <OrderStyles onSubmit={submitOrder}>
        <fieldset disabled={loading}>
          <legend>Your Info</legend>
          <label htmlFor="name">Name</label>
          <input type="text" name="name" id="name" value={values.name} onChange={updateValue} />
          <label htmlFor="email">Email</label>
          <input type="email" name="email" id="email" value={values.email} onChange={updateValue} />
          <input
            type="mapleSyrup"
            name="mapleSyrup"
            id="mapleSyrup"
            value={values.mapleSyrup}
            className="mapleSyrup"
            onChange={updateValue}
          />
          <label htmlFor="coupon">Coupon</label>
          <input type="text" name="coupon" id="coupon" value={values.coupon} onChange={updateValue} />
        </fieldset>
        <fieldset disabled={loading} className="menu">
          <legend>Menu</legend>
          {pizzas.map((pizza) => (
            <MenuItemStyles key={pizza.id}>
              <Img width="50" height="50" fluid={pizza.image.asset.fluid} alt={pizza.name} />
              <h2>{pizza.name}</h2>
              <div>
                {['S', 'M', 'L'].map((size) => (
                  <button type="button" key={size} onClick={() => addToOrder({ id: pizza.id, size })}>
                    {size} {formateMoney(calculatePizzaPrice(pizza.price, size))}
                  </button>
                ))}
              </div>
            </MenuItemStyles>
          ))}
        </fieldset>
        <fieldset disabled={loading} className="order">
          <legend>Order</legend>
          <PizzaOrder order={order} removeFromOrder={removeFromOrder} pizzas={pizzas} />
        </fieldset>
        <fieldset disabled={loading}>
          <h3>Your total is {formateMoney(calculateOrderTotal(order, pizzas))}</h3>
          <div>{error ? <p>Error: {error}</p> : ''}</div>
          <button type="submit" disabled={loading}>
            {loading ? 'Placing Order...' : 'Order Ahead'}
          </button>
        </fieldset>
      </OrderStyles>
      <p>Hey! I'm the Order page</p>
    </>
  )
}

export const query = graphql`
  query {
    pizzas: allSanityPizza {
      nodes {
        name
        id
        slug {
          current
        }
        price
        image {
          asset {
            fluid(maxWidth: 100) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
